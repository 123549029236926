import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import App from './App';
import './index.css';
import { ThemeProvider } from 'styled-components';
import { theme } from './common/theme';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Auth0Provider } from '@auth0/auth0-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createRoot } from 'react-dom/client';

Sentry.init({
	dsn: 'https://bef9381320164f39849df689e6a97556@o4504134728220672.ingest.sentry.io/4504134745522177',
	integrations: [new BrowserTracing()],
	tracesSampleRate: 1.0,
});

const container: HTMLElement | null = document.getElementById('root');

if (container) {
	const root = createRoot(container); // createRoot(container!) if you use TypeScript
	root.render(
		<Auth0Provider
			domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
			clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
			authorizationParams={{
				redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL as string,
				audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
				scope: 'openid profile email offline_access',
			}}
			cacheLocation={'localstorage'}
			useRefreshTokens={true}
			useRefreshTokensFallback={true}
		>
			<ThemeProvider theme={theme}>
				<Provider store={store}>
					<App />
					<ToastContainer />{' '}
				</Provider>
			</ThemeProvider>
		</Auth0Provider>,
	);
}
